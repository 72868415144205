import React from "react"
import { graphql } from "gatsby"
import Top from "../components/Post/Top"
import PostContent from "../components/Post/PostContent"
import Layout from "../components/Layout"
import Sidebar from "../components/Post/Sidebar"
import { useAppState } from "../components/Context"
import Seo from "gatsby-plugin-wpgraphql-seo"

const PostTemplate = (props) => {
  const { data } = props
  const postData = data.wpPost.postData
  const postMeta = data.wpPost.postMeta
  const color = postData.sidebarHeaderColor

  const { currentLinkPage } = useAppState()

  return (
    <>
      <Seo post={data.wpPost} />
      <Layout
        location={props.location}
        title={postMeta.title}
        description={postMeta.desc}
        image={postData?.headerImage?.localFile?.childImageSharp?.fluid}
        backLink={currentLinkPage}
      >
        <Top postData={postData} />
        <div
          className={
            "container-fluid" + (postData?.underlineLinks ? " smartmove " : "")
          }
        >
          <div className="row justify-content-center">
            <div className="col-lg-10 content">
              <div className="row">
                <div className="col-12 col-lg-8 content-width">
                  <h1>{postData.postHeader}</h1>
                </div>
                <div className="col-12 col-lg-8 content-width">
                  <PostContent postContent={postData.postContent} />
                </div>

                <Sidebar color={color} sidebar={props.data.wpPost.postData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PostTemplate

export const postQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      postData {
        postContent {
          ... on WpPost_Postdata_PostContent_PostContentLead {
            fieldGroupName
            postContentLeadText
          }
          ... on WpPost_Postdata_PostContent_PostContentWysiwyg {
            fieldGroupName
            postContentWysiwygEditor
          }
          ... on WpPost_Postdata_PostContent_PostContentJobFinder {
            fieldGroupName
          }
          ... on WpPost_Postdata_PostContent_PostContentPersons {
            fieldGroupName
            postContentPersonRows {
              postContentPersonEditor
              postContentPersonImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: TRACED_SVG
                      quality: 85
                    )
                  }
                }
                altText
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentHalfImages {
            fieldGroupName
            postContentHalfImageRows {
              postContentHalfImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      quality: 85
                    )
                  }
                }
                altText
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentBrokers {
            fieldGroupName
            postContentBrokerRows {
              postContentBrokerEditor
              postContentBrokerImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      quality: 85
                    )
                  }
                }
                altText
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentGrid {
            fieldGroupName
            postContentGridRows {
              postContentGridRowHeader
              postContentGridRowLink {
                url
                title
                target
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentImage {
            fieldGroupName
            postContentImageField {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    quality: 85
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentEmbed {
            fieldGroupName
            postContentEmbedUrl
          }
        }
        headerImage {
          localFile {
            childImageSharp {
              fluid(
                maxWidth: 4800
                quality: 85
                toFormat: JPG
                srcSetBreakpoints: [10, 7200]
              ) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
            localURL
          }
          altText
        }
        sidebarHeaderColor
        sidebarHeader
        sidebarContent {
          ... on WpPost_Postdata_SidebarContent_SidebarContentTable {
            fieldGroupName
            sidebarTable {
              rightCell
              leftCell
            }
          }
          ... on WpPost_Postdata_SidebarContent_SidebarContentList {
            fieldGroupName
            sidebarList {
              listItem
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_SidebarContent_SidebarContentNav {
            fieldGroupName
            sidebarContentNavTitle
            sidebarNav {
              link {
                url
                title
                target
              }
            }
          }
          ... on WpPost_Postdata_SidebarContent_SidebarContentText {
            fieldGroupName
            sidebarTextarea
          }
        }
        postHeader
        headerVideoWebm {
          localFile {
            localURL
          }
        }
        headerVideoMp4 {
          localFile {
            localURL
          }
        }
        useHeaderVideo
        underlineLinks
      }
      postMeta {
        title
        desc
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
