import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Menu from "./Menu"
import MenuStart from "./StartPage/MenuStart"
import Footer from "./Footer"
import useWindowDimensions from "../hooks/useWindowDimensions"
import useSiteMetaQuery from "../hooks/useSiteMetaQuery"
import useSiteSeoQuery from "../hooks/useSiteSeoQuery"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"

const Component = (props) => {
  const {
    children,
    image,
    title,
    description,
    location,
    menu,
    startMenu,
    startMenuLinkData,
    frontpage,
    backLink,
  } = props

  const pathMatch = location.pathname.match("^([/][0-9a-zA-Z-]+)([/].*)")
  const backLinked = backLink
    ? backLink
    : !pathMatch
    ? "/"
    : pathMatch[2] === "/"
    ? "/"
    : pathMatch[1] + "/"

  const siteMeta = useSiteMetaQuery()
  const siteSeo = useSiteSeoQuery()
  const { height } = useWindowDimensions()
  const [realHeight, setRealHeight] = useState(height)

  useEffect(() => {
    if (height > 0) {
      setRealHeight(height)
    }
  }, [height])

  return (
    <>
      <SEOContext.Provider value={{ global: siteSeo }}>
        <Helmet
          htmlAttributes={{
            lang: location.pathname.includes("international") ? "en" : "sv",
          }}
        >
          <body className="subpage" />
          {!!frontpage && <title>{title ?? "Smart Move"}</title>}
          {!frontpage && (
            <title>{title ? title + " - " + siteMeta.title : title}</title>
          )}

          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0"
          />

          {realHeight && realHeight > 0 && (
            <style type="text/css">
              {`
        :root {
          --innerHeight: ${realHeight}px
          }
          `}
            </style>
          )}

        </Helmet>
        
        {!!startMenu && (
          <MenuStart location={location} linkData={startMenuLinkData} />
        )}
        {!!menu && <Menu backLink={backLinked} />}
        <div className={props.class}>{children}</div>
        {!!menu && <Footer location={location} />}
      </SEOContext.Provider>
    </>
  )
}
Component.defaultProps = {
  menu: true,
  startMenu: false,
  frontpage: false,
  backLink: false,
  startMenuLinkData: false,
}

export default Component
