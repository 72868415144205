import React from "react"
import DynamicLink from "../../DynamicLink"

const SidebarContentNav = (props) => {
  const { sidebarContent } = props

  return (
    <nav className="link-list list-group">
      {sidebarContent?.sidebarContentNavTitle && (
        <div className="list-group-item">
          {sidebarContent.sidebarContentNavTitle}
        </div>
      )}
      {sidebarContent?.sidebarNav &&
        sidebarContent.sidebarNav.map((navItem) => (
          <DynamicLink
            key={navItem.link.title.toString()}
            to={navItem.link.url}
            target={navItem.link.target}
            inline={false}
          >
            {navItem.link.title}
          </DynamicLink>
        ))}
    </nav>
  )
}

export default SidebarContentNav
