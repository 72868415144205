import React from "react"
import { Link } from "gatsby"
import Logo from "./Logo"
import useSiteOptionsQuery from "../hooks/useSiteOptionsQuery"

const Component = (props) => {
  const { location } = props
  const siteOptions = useSiteOptionsQuery()
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Logo light={true} />
          </div>
          <div className="col-12">
            <ul className="nav justify-content-center">
              <li className="nav-item">
                {location.pathname.includes("international") && (
                  <Link
                    className="nav-link active"
                    target={siteOptions?.articleFooter?.linkEn?.target}
                    to={siteOptions?.articleFooter?.linkEn?.url}
                  >
                    {siteOptions?.articleFooter?.linkEn?.title}
                  </Link>
                )}
                {!location.pathname.includes("international") && (
                  <Link
                    className="nav-link active"
                    target={siteOptions?.articleFooter?.link?.target}
                    to={siteOptions?.articleFooter?.link?.url}
                  >
                    {siteOptions?.articleFooter?.link?.title}
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
