import React from "react"
import SidebarContentList from "./SidebarContentList"
import SidebarContentNav from "./SidebarContentNav"
import SidebarContentTable from "./SidebarContentTable"
import SidebarContentTextarea from "./SidebarContentTextarea"

const getSidebarContentComponent = (sidebarContentItem, i) => {
  let component

  const key = sidebarContentItem.fieldGroupName + "_" + i

  switch (sidebarContentItem.fieldGroupName) {
    case "Post_Postdata_SidebarContent_SidebarContentList":
      component = (
        <SidebarContentList key={key} list={sidebarContentItem.sidebarList} />
      )
      break

    case "Post_Postdata_SidebarContent_SidebarContentText":
      component = (
        <SidebarContentTextarea
          key={key}
          textarea={sidebarContentItem.sidebarTextarea}
        />
      )
      break

    case "Post_Postdata_SidebarContent_SidebarContentNav":
      component = (
        <SidebarContentNav key={key} sidebarContent={sidebarContentItem} />
      )
      break

    case "Post_Postdata_SidebarContent_SidebarContentTable":
      component = (
        <SidebarContentTable
          key={key}
          sidebarTable={sidebarContentItem.sidebarTable}
        />
      )
      break

    default:
      component = ""
      break
  }
  return component
}

const SidebarContent = (props) => {
  const { sidebar } = props

  return sidebar?.sidebarContent
    ? sidebar.sidebarContent?.map((sidebarContentItem, i) =>
        getSidebarContentComponent(sidebarContentItem, i)
      )
    : ""
}

export default SidebarContent
