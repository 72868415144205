import React, { useRef, useState, useEffect } from "react"
import Img from "gatsby-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

const Component = (props) => {
  const { image } = props
  const { scrollY } = useViewportScroll()
  const imageRef = useRef(null)
  const [imageTop, setImageTop] = useState(0)
  const [imageBot, setImageBot] = useState(0)

  const { width, height } = useWindowDimensions()

  useEffect(() => {
    const image = imageRef.current.getBoundingClientRect()
    const offset = window.pageYOffset || 0
    setImageTop(image.top + offset)
    setImageBot(image.bottom + window.innerHeight + offset)
  }, [width, height, imageRef])

  const x = useTransform(
    scrollY,
    [imageTop, imageBot],
    [props.imageX.start, props.imageX.end]
  )

  const y = useTransform(
    scrollY,
    [imageTop, imageBot],
    [props.imageY.start, props.imageY.end]
  )
  return (
    <div className={`top-wrap`} ref={imageRef}>
      <div className="image">
        <motion.div
          className="image-wrap"
          style={{
            padding: props.imageOffset.join(" "),
            translateX: x,
            translateY: y,
          }}
        >
          {image && (
            <Img
              alt={props.alt}
              loading="eager"
              objectFit="contain"
              objectPosition="50% 50%"
              imgStyle={{
                objectFit: "cover",
                objectPosition: "50% 50%",
              }}
              fluid={image}
            />
          )}
        </motion.div>
      </div>
    </div>
  )
}

Component.defaultProps = {
  imageX: { start: 0, end: 0 },
  imageY: { start: 0, end: 0 },
  zoom: { start: 0, end: 0 },
  imageOffset: [null],
  alt: "",
}

export default Component
