import React from "react"

import SidebarContent from "./Sidebar/SidebarContent"

const Sidebar = (props) => {
  const { color, sidebar } = props

  return (
    <div className="col-12 col-lg-4">
      {sidebar.sidebarHeader ? (
        <div className="sidebar" style={{ color: color }}>
          <h2>{sidebar.sidebarHeader}</h2>
          <SidebarContent sidebar={sidebar} />
        </div>
      ) : null}
    </div>
  )
}

export default Sidebar
