import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const PostContentBrokers = (props) => {
  return (
    <div className="row images maklare">
      {props.content.map((brokerRow) => {
        return (
          <div
            className="col-12 col-md-6"
            key={brokerRow?.postContentBrokerEditor.toString()}
          >
            {brokerRow?.postContentBrokerImage?.localFile && (
              <GatsbyImage
                alt={brokerRow.postContentBrokerImage?.altText}
                image={getImage(brokerRow.postContentBrokerImage?.localFile)}
              />
            )}
            {parse(brokerRow?.postContentBrokerEditor)}
          </div>
        )
      })}
    </div>
  )
}

export default PostContentBrokers
