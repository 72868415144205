import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const PostContentPersons = (props) => {
  return (
    <div className="contact">
      {props.content.map((personRow) => {
        return (
          <div
            key={personRow?.postContentPersonEditor.toString()}
            className="row"
          >
            <div className="image col-12 col-lg-5">
              {personRow?.postContentPersonImage?.localFile && (
                <GatsbyImage
                  alt={personRow.postContentPersonImage?.altText}
                  image={getImage(personRow.postContentPersonImage?.localFile)}
                />
              )}
            </div>
            <div className="col-12 col-lg-7 info align-self-center">
              {parse(personRow?.postContentPersonEditor)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PostContentPersons
